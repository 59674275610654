<template>
  <iframe v-if="url" allow="clipboard-read; clipboard-write" :src="url"></iframe>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SpecialistsDirectory',
  mounted () {
    this.getUrl()
  },
  data () {
    return {
      country: 'CO',
      url: null,
      query: 'utm_source=specialist-app&utm_medium=specialists&utm_campaign=specialist-referral'
    }
  },
  methods: {
    getUrl () {
      window.addEventListener('message', (event) => {
        switch (event.data.action) {
          case 'copyClipboard': {
            const url = `${process.env.VUE_APP_LANDING_URL}/terapeutas-psicologos-en-linea/${this.$route.params.slug}/?${this.query}`
            navigator.clipboard.writeText(url)
            break
          }
        }
      })
      this.url = `${process.env.VUE_APP_EAGLE_URL}/detail/${this.language.lang}/${this.country}/${this.$route.params.slug}?back=specialist-home`
    }
  },
  computed: {
    ...mapGetters({
      language: 'language'
    })
  }
}
</script>

<style lang="sass" scoped>
iframe
  width: 100%
  height: 100vh
  position: fixed
  border-radius: 0 !important
  outline: unset !important
</style>
